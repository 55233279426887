import React from 'react';
import Select from 'react-select';
import { Link, navigate } from 'gatsby';
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/components/prism-bash.min';
import 'prismjs/components/prism-json.min';

import HeaderSecondary from '../_global/HeaderSecondary.jsx';
import Banner from '../_global/Banner.jsx';
import MainLayout from '../layouts/MainLayout.jsx';

import './DocsPage.scss';

class DocsPage extends React.Component {

    content = React.createRef();

    componentDidMount() {
        Prism.highlightAllUnder(this.content.current);
    }
    componentDidUpdate() {
        Prism.highlightAllUnder(this.content.current);
    }

    onChangeOption = path => {

        navigate(path);
    };

    renderValue = option => {
        return <h3 className="selected-doc">{option.title}</h3>;
    };

    render() {

        const { pathContext } = this.props;

        return (
            <MainLayout>
                <div className="docs-page">

                    <HeaderSecondary />

                    <Banner>
                        <Select
                            name="form-field-name"
                            clearable={false}
                            value={pathContext.url}
                            onChange={this.onChangeOption}
                            options={pathContext.options || []}
                            valueKey="url"
                            labelKey="title"
                            simpleValue={true}
                            optionRenderer={this.renderValue}
                            valueRenderer={this.renderValue}
                        />
                    </Banner>

                    <div className="container">

                        <div className="row">

                            <div className="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">

                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{__html: pathContext.html}}
                                    ref={this.content}
                                />

                            </div>

                        </div>

                    </div>

                </div>

            </MainLayout>
        );
    }
}

export default DocsPage;